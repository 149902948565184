<template>
  <div class="team w-10/12 mx-auto">
    <div>
      <div class="flex mb-4">
        <div class="filter-push">
          <input type="radio" :checked="!filterActive" :value="null" id="locationAll" @click="allClicked">
          <label for="locationAll">{{ labels.all }}</label>
        </div>
        <div class="filter-push">
          <input type="radio" v-model="teamFilter.location" :value="0" id="locationHamburg">
          <label for="locationHamburg">Hamburg</label>
        </div>
        <div class="filter-push">
          <input type="radio" v-model="teamFilter.location" :value="1" id="locationLeipzig">
          <label for="locationLeipzig">Leipzig</label>
        </div>
        <div class="filter-push">
          <input type="radio" v-model="teamFilter.location" :value="2" id="locationOsnabrueck">
          <label for="locationOsnabrueck">Osnabrück</label>
        </div>
      </div>
      <div class="flex flex-wrap items-start align-top">
        <div class="filter-push filter-push--sub mr-2 items-center"
           v-for="specialization in options.specializations"
           :key="specialization">
          <input type="radio" v-model="teamFilter.specializations" :value="specialization" :id="specialization">
          <label :for="specialization">{{ specialization }}</label>
        </div>
      </div>
      <transition-group name="member" tag="div" class="team__list flex flex-wrap md:-mx-3 lg:-mx-6 mt-12">
        <div class="w-4/10 md:w-1/4 mb-6 md:mb-12 md:px-3 lg:px-6"
          v-for="member in paginatedMembers"
          :key="member.title">
          <TeamMember :member="member" />
        </div>
      </transition-group>
    </div>
    <div class="text-center mt-8 sm:mt-16" v-show="filteredMembers.length > 0 && !isLoading">
      <button @click="showMore()" class="button" v-show="morePagesAvailable">
        {{ labels.loadmore }}
      </button>
    </div>
    <div class="w-full text-center">
      <Spinner v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import TeamMember from './TeamMember'
import Spinner from './Spinner'

const MEMBERS_PER_PAGE = 12

export default {
  props: {
    labels: {
      type: Object,
      default() {
        return {}
      }
    },
    apiBase: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      team: [],
      teamFilter: {
        specializations: [],
        location: null
      },
      options: {
        locations: ['hamburg', 'leipzig', 'osnabrueck'],
        specializations: [],
      },
    }
  },
  components: {
    Spinner,
    TeamMember
  },
  computed : {
    filteredMembers() {
      let selection = this.team

      if (this.teamFilter.specializations.length) {
        selection = selection.filter(item => {
          return item.specializations.some(c => {
            return this.teamFilter.specializations.includes(c)
          })
        })
      }

      if (this.teamFilter.location !== null) {
        selection = selection.filter(item => {
          return item.location === this.options.locations[this.teamFilter.location]
        })
      }

      selection.sort(function (a, b) {
        const aLast = a.title.toString().split(',')[0].split(' ').splice(-1)
        const bLast = b.title.toString().split(',')[0].split(' ').splice(-1)
        return aLast < bLast ? -1 : 1
      })

      return selection
    },
    paginatedMembers() {
      return this.filteredMembers.slice(0, this.page * MEMBERS_PER_PAGE)
    },
    morePagesAvailable() {
      return this.page < Math.ceil(this.filteredMembers.length / MEMBERS_PER_PAGE)
    },
    filterActive() {
      return this.teamFilter.specializations.length > 0
        || this.teamFilter.location !== null
    },
  },
  created() {
    const API_URL = `${this.apiBase}/api/team.json`
    axios.get(API_URL)
    .then(result => {
      if (result.data && result.data.team) {
        this.team = result.data.team
        this.populate()
        this.isLoading = false
      } else {
        throw new Error('Invalid API response!')
      }
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log('error:', error)
    })
  },
  methods: {
    allClicked() {
      this.clearFilter()
    },
    showMore() {
      this.page++
    },

    populate() {
      let specializations = []

      this.team.forEach(v => specializations.push(...v.specializations))

      this.options.specializations = specializations.filter(function(item, index) {
        return specializations.indexOf(item) >= index
      }).sort()
    },

    clearFilter() {
      this.teamFilter.specializations = []
      this.teamFilter.location = null
    },
  }
}
</script>

<style>

.filter {
  @apply text-brand-blue;
}

.filter-push {
  @apply block flex-none mb-1;

  & input {
    display: none;
  }

  & label {
    @apply font-sofia-bold text-sm inline-block text-black text-center w-full h-full px-4 py-1 cursor-pointer;
    background-color: white;
    text-transform: uppercase;
    transition: all 0.1s;
  }

  & input:checked {
    & + label {
      @apply text-brand-blue;
      background-color: rgba(204, 124, 87, 0.4);
      border-radius: 150px;
      text-transform: uppercase;
    }
  }
}

.filter-push--sub {
  & label {
    @apply text-gray;
    text-transform: none;
  }
  & input:checked {
    & + label {
      @apply pb-2;
      color: #777777;
      text-transform: none;
    }
  }
}

@screen mo {
  .team__list > div {
    @apply mx-2;

    &:first-child,
    &:nth-child(odd) {
      @apply ml-0;
    }
  }
}
</style>
