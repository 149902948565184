var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-navigation md:flex"},_vm._l((_vm.tabs),function(tab,index){return _c('button',{key:index,class:[
    'tab-navigation__button',
    'u-h1',
    'text-h3-fluid',
    'mr-4 lg:mr-8 font-sofia-semi',
    {'is-active': _vm.currentTab == index}
    ],on:{"click":function($event){return _vm.onClick(index)}}},[_vm._v("\n    "+_vm._s(tab.city)+"\n  ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }