<template>
  <div class="tab-navigation md:flex">
    <button
      v-for="(tab, index) in tabs"
      :key="index"
      :class="[
      'tab-navigation__button',
      'u-h1',
      'text-h3-fluid',
      'mr-4 lg:mr-8 font-sofia-semi',
      {'is-active': currentTab == index}
      ]"
      @click="onClick(index)">
      {{ tab.city }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      currentTab: 0
    }
  },
  methods: {
    onClick(id) {
      this.currentTab = id
      this.$emit('tab-changed', id)
    }
  }
}
</script>

<style>
.tab-navigation__button.is-active{
  color: #CC7C57;
}
@screen mo{
  .tab-navigation__button {
    font-size: 32px !important;
  }
}

</style>
