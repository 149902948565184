<template>
  <div class="contact flex mo:flex-col mo:w-10/12 mo:mx-auto">
    <div class="md:hidden mb-8">
      <TabNavigation :tabs="locations" @tab-changed="setActiveCity" />
    </div>
    <div class="relative md:w-5/12 md:mr-2">
      <div :class="['contact__image', { 'is-active' : currentTab == 0 }]">
        <slot name="image1" />
      </div>
      <div :class="[
        'contact__image',
        'absolute',
        'top-0',
        'left-0',
        'z-10',
        { 'is-active' : currentTab == 1 }]">
         <slot name="image2" />
      </div>
      <div :class="[
        'contact__image',
        'absolute',
        'top-0',
        'left-0',
        'z-10',
        { 'is-active' : currentTab == 2 }]">
        <slot name="image3" />
      </div>
      <div class="md:ml-12 lg:ml-24 font-sofia-bold">
        <transition-group name="fade" mode="out-in">
          <div v-if="currentTab == 0" key="1">
            <slot name="address1" />
          </div>
          <div v-else-if="currentTab == 1" key="2">
            <slot name="address2" />
          </div>
          <div v-else key="3">
            <slot name="address3" />
          </div>
        </transition-group>
      </div>
    </div>

    <div class="md:w-6/12 md:pl-8 lg:pl-16 mo:flex mo:flex-col">
      <div class="mo:hidden">
        <TabNavigation :tabs="locations" @tab-changed="setActiveCity" />
      </div>
      <div class="contact__info mo:h-auto md:my-8 mo:mt-8">
        <transition-group name="fade" mode="out-in">
          <div v-if="currentTab == 0" key="1">
            <slot name="info1" />
          </div>
          <div v-if="currentTab == 1" key="2">
            <slot name="info2" />
          </div>
          <div v-else key="3">
            <slot name="info3" />
          </div>
        </transition-group>
      </div>
      <form class="mt-12 mo:order-2" :class="{'disabled': state == 'thanks' }" novalidate @submit.prevent="submit" ref="form">
        <input type="hidden" name="action" value="contact-form/send">
        <div class="lg:flex lg:flex-wrap disablable">
          <div class="w-full lg:w-1/2 mb-6 lg:mb-0 lg:pr-4">
            <label class="block text-gray-600 mb-2 font-sofia-semi" for="name">{{ labels.name }}*</label>
            <input class="appearance-none rounded block w-full bg-brown-half text-gray-600 py-3 pb-4 px-5 focus:outline-none focus:text-black" :placeholder="labels.name_p" :class="{ 'border-red-500': $v.contact.name.$error, 'focus:border-black': !$v.contact.name.$error }" type="text" id="name" name="fromName" v-model="$v.contact.name.$model">
            <template v-if="$v.contact.name.$error">
              <span class="text-red-500 text-xs absolute mt-px" v-if="!$v.contact.name.required">{{ labels.notEmpty }}</span>
            </template>
          </div>
          <div class="md:flex md:flex-wrap lg:w-1/2">
            <div class="w-full lg:pl-4">
              <label class="block text-gray-600 mb-2 font-sofia-semi" for="email">{{ labels.email }}*</label>
              <input class="appearance-none rounded block w-full bg-brown-half text-gray-600 py-3 pb-4 px-5 focus:outline-none focus:text-black" :placeholder="labels.email_p" :class="{ 'border-red-500': $v.contact.email.$error, 'focus:border-black': !$v.contact.email.$error }" id="email" type="text" name="fromEmail" v-model="$v.contact.email.$model">
              <template v-if="$v.contact.email.$error">
                <span class="text-red-500 text-xs absolute mt-px" v-if="!$v.contact.email.required">{{ labels.notEmpty }}</span>
                <span class="text-red-500 text-xs absolute mt-px" v-if="!$v.contact.email.email">{{ labels.notValidEmail }}</span>
              </template>
            </div>
          </div>
          <div class="md:flex md:flex-wrap lg:w-1/2">
            <div class="w-full mt-8 lg:pr-4">
              <label class="block text-gray-600 mb-2 font-sofia-semi" for="phone">{{ labels.phone }}</label>
              <input class="appearance-none rounded block w-full bg-brown-half text-gray-600 py-3 pb-4 px-5 focus:outline-none focus:text-black" :placeholder="labels.phone_p" :class="{ 'border-red-500': $v.contact.phone.$error, 'focus:border-black': !$v.contact.phone.$error }" id="phone" type="text" name="message[Phone]" v-model="$v.contact.phone.$model">
              <template v-if="$v.contact.phone.$error">
                <span class="text-red-500 text-xs absolute mt-px" v-if="!$v.contact.phone.phone">{{ labels.notValidPhone }}</span>
              </template>
            </div>
          </div>
        </div>
        <div class="w-full mt-8 disablable">
          <label class="block text-gray-600 mb-2 font-sofia-semi" for="message">{{ labels.message }}*</label>
          <textarea class="mo:h-64 appearance-none rounded block w-full bg-brown-half text-gray-600 py-3 pb-4 px-5 focus:outline-none focus:text-black" :placeholder="labels.message_p" :class="{ 'border-red-500': $v.contact.message.$error, 'focus:border-black': !$v.contact.message.$error }" id="message" type="text" name="message[body]" v-model="$v.contact.message.$model"></textarea>
          <template v-if="$v.contact.message.$error">
            <span class="text-red-500 text-xs absolute mt-px" v-if="!$v.contact.message.required">{{ labels.notEmpty }}</span>
          </template>
        </div>
        <div class="w-full mt-4">
          <p class="text-s">*{{ labels.required }}</p>
        </div>
        <div class="w-full mt-8 mo:mb-16">
          <template v-if="state == 'form' || state == 'error'">
            <button type="submit" class="button mo:mx-auto flex justify-center items-center">{{ labels.submit }}</button>
          </template>
          <template v-if="state == 'submitting'">
            <Spinner />
          </template>
          <template v-if="state == 'thanks'">
            <h2 class="text-h2-fluid text-brand-blue">{{ labels.success }}</h2>
          </template>
        </div>
        <input name="fax" id="fax" type="text">
        <input type="hidden" name="toLocation" :value="currentTab">
      </form>
      <div class="w-full h-64 rounded overflow-hidden mt-8 mo:order-1">
        <template v-if="mapsPermitted == 1">
          <GmapMap
            ref="gmap"
            class="rounded-xl w-full h-full relative"
            :center="locations[0].coordinates"
            :zoom="13"
            :options="{
              zoomControl: true,
              scrollwheel: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false
            }"
            map-type-id="terrain">
            <gmap-custom-marker :marker="locations[0].coordinates">
              <div class="w-4 h-4 bg-brand-blue rounded-full map-maker"></div>
            </gmap-custom-marker>
            <gmap-custom-marker :marker="locations[1].coordinates">
              <div class="w-4 h-4 bg-brand-blue rounded-full map-maker"></div>
            </gmap-custom-marker>
            <gmap-custom-marker :marker="locations[2].coordinates">
              <div class="w-4 h-4 bg-brand-blue rounded-full map-maker"></div>
            </gmap-custom-marker>
          </GmapMap>
        </template>
        <template v-else>
          <div class="w-full h-full bg-light-gray flex justify-center items-center">
            <button class="link cursor-pointer" @click.prevent="enableGoogleMaps">{{ labels.googleMapsPermission }}</button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { validationMixin } from 'vuelidate'
import { sameAs, required, email, helpers } from 'vuelidate/lib/validators' // eslint-disable-line no-unused-vars
import { gmapApi } from 'vue2-google-maps'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import TabNavigation from './TabNavigation'
import serialize from 'form-serialize'
import Spinner from './Spinner'
import eventBus from '@/util/event-bus'
import { EVENT } from '@/util/enums'
import PermissionsHelper from '@/util/permissions'
const phone = helpers.regex('phone', /^\+?[0-9]{2}[0-9 ]+$/)

export default {
  components: {
    Spinner,
    TabNavigation,
    'gmap-custom-marker': GmapCustomMarker,
  },
  mixins: [validationMixin],
  validations() {
    return {
      contact: {
        name: { required },
        phone: { phone },
        email: { required, email },
        message: { required }
      }
    }
  },
  props: {
    labels: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      state: 'form',
      mapsPermitted: 0,
      locations: [
        {
          city: 'Hamburg',
          coordinates: {
            lat: 53.556484,
            lng: 9.985314
          }
        },
        {
          city: 'Leipzig',
          coordinates: {
            lat: 51.336849,
            lng: 12.374764
          }
        },
        {
          city: 'Osnabrück',
          coordinates: {
            lat: 52.27478026056516,
            lng: 8.049224897959714
          }
        }
      ],
      currentTab: 0,
      contact: {
        email: '',
        name: '',
        phone: '',
        message: '',
        recaptchaResponse: ''
      }
    }
  },
  computed: {
    google: gmapApi
  },
  mounted() {
    this.updatePermission()
    eventBus.$on(EVENT.PERMISSIONS_CHANGED, this.updatePermission)
  },
  methods: {
    updatePermission() {
      this.mapsPermitted = PermissionsHelper.mapsPermission
    },
    enableGoogleMaps() {
      this.mapsPermitted = 1
      PermissionsHelper.mapsPermission = this.mapsPermitted
      eventBus.$emit(EVENT.PERMISSIONS_CHANGED)
    },
    setActiveCity(id) {
      this.currentTab = id
      this.$refs.gmap.$mapPromise.then((map) => {
        map.panTo(this.locations[id].coordinates)
      })
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.state = 'submitting'

      axios({
        url: '/',
        method: 'POST',
        data: serialize(this.$refs.form)
      })
      .then(response => { // eslint-disable-line no-unused-vars
        this.state = 'thanks'
      })
      .catch(error => {
        console.log(error)
        this.state = 'error'
      })
    }
  }
}
</script>


<style>

form input#fax {
  display: none;
}

.disabled .disablable {
  opacity: 0.5;
  pointer-events: none;
}

.contact__image {
  opacity: 0;
  transition: opacity .3s ease-in-out;

  &.is-active {
   opacity: 1;
  }
}
</style>
